/* You can add global styles to this file, and also import other style files */
@import "@angular/material/prebuilt-themes/indigo-pink.css";
@import "~bootstrap-icons/font/bootstrap-icons.css";

@font-face {
  font-family: "Montserrat";
  src: url("assets/fonts/Montserrat/Montserrat-VariableFont_wght.ttf") format("truetype");
  font-weight: 100 900;
  font-style: normal;
}

body {
  margin: 0;
  padding: 0;
  font-family: "Montserrat", sans-serif !important;
}

/* Apply Montserrat to all elements */
* {
  font-family: "Montserrat", sans-serif !important;
}

.cdk-overlay-container {
  z-index: 1200 !important;
}

.mt-50 {
  margin-top: 50px;
}

.mt-55 {
  margin-top: 55px;
}

.mt-70 {
  margin-top: 70px;
}

.mt-140 {
  margin-top: 140px;
}

html,
body {
  height: 100%;
}

.page-title,
.modal-title {
  font-family: inherit;
}

.spin {
  animation: spin 1.5s linear infinite;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* Loading text styling */
.loading-text {
  color: #6658dd;
  font-size: 1.5rem;
  font-weight: 500;
  display: inline-flex;
  align-items: center;
}

.loading-text .bi-arrow-repeat {
  color: #4a81d4;
  margin-left: 8px;
  animation: spin 1.5s linear infinite;
}

/* Apply animation to all arrow-repeat icons in loading contexts */
.bi-arrow-repeat {
  animation: spin 1.5s linear infinite;
  display: inline-block;
  margin-left: 8px;
}

/* Diamond styles for backward compatibility */
.bi-diamond {
  color: #4a81d4;
  animation: diamond-pulse 1.5s ease infinite;
  display: inline-block;
  margin-left: 8px;
}

.text-primary .bi-diamond {
  color: #4a81d4;
}

@keyframes diamond-pulse {
  0% { opacity: 0.6; }
  50% { opacity: 1; }
  100% { opacity: 0.6; }
}
